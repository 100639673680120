import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const Attraction = () => (
  <Layout sermonSrc="https://player.vimeo.com/video/392250270">
    <SEO title="Attraction - Love Song" />
  </Layout>
)

export default Attraction
